/* Typography CSS */
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.eot');
    src: local('../fonts/Poppins Regular'), local('Poppins-Regular'),
        url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Light';
    src: url('../fonts/Poppins-Light.eot');
    src: local('../fonts/Poppins Light'), local('Poppins-Light'),
        url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Light.woff2') format('woff2'),
        url('../fonts/Poppins-Light.woff') format('woff'),
        url('../fonts/Poppins-Light.ttf') format('truetype'),
        url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.eot');
    src: local('../fonts/Poppins Medium'), local('Poppins-Medium'),
        url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.eot');
    src: local('../fonts/Poppins SemiBold'), local('Poppins-SemiBold'),
        url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.eot');
    src: local('../fonts/Poppins Bold'), local('Poppins-Bold'),
        url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'DroidKufiBold';
	src: url('../fonts/DroidKufiBold.eot');
	src: url('../fonts/DroidKufiBold.eot') format('embedded-opentype'), url('../fonts/DroidKufiBold.woff2') format('woff2'), url('../fonts/DroidKufiBold.woff') format('woff'), url('../fonts/DroidKufiBold.ttf') format('truetype'), url('../fonts/DroidKufiBold.svg#DroidKufiBold') format('svg');
}
@font-face {
	font-family: 'DroidKufiRegular';
	src: url('../fonts/DroidKufiRegular.eot');
	src: url('../fonts/DroidKufiRegular.eot') format('embedded-opentype'), url('../fonts/DroidKufiRegular.woff2') format('woff2'), url('../fonts/DroidKufiRegular.woff') format('woff'), url('../fonts/DroidKufiRegular.ttf') format('truetype'), url('../fonts/DroidKufiRegular.svg#DroidKufiRegular') format('svg');
}




body{
    font-family:'Poppins', 'DroidKufiRegular';
    margin:0 !important;
    padding:0 !important;
    overflow-x:hidden !important;
    background:#fff;
}
div,p,a,ul li,span,b,strong,select,input,button,h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    font-family:'Poppins', 'DroidKufiRegular';
    letter-spacing: 1px;
}
a{
    transition:all ease 0.5s;
    -o-transition:all ease 0.5s;
    -webkit-transition:all ease 0.5s;
    text-decoration: none;
}
a:focus,a:active,a:visited,a:hover{
    text-decoration:none;
}

*,:before,:after{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
ul,ol{
    margin:0;
    padding:0;
    list-style:none;
}
b,strong{
    font-weight:600;
}
select{
    -webkit-appearance:none;
    -moz-appearance:none;
    -o-appearance:none;
    -ms-appearance:none;
    text-indent:1px;
    -o-text-overflow:'';
    text-overflow:'';
}
select::-ms-expand, input[type=text]::-ms-clear{
    display:none;
}
input:focus, button:focus, select:focus{
    outline:none;
    box-shadow: none;
}
input,button,select,textarea{
    font-family:inherit;
    font-size:inherit;
    line-height:inherit;
}
button{
    cursor:pointer;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    margin-bottom:0;
    font-weight:normal;
    font-family:inherit;
    font-size:inherit;
    line-height:inherit;
}
html{
    -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
}
img{
    vertical-align:middle;
    max-width:100%;
    height:auto;
}
.overflow-hidden{
    overflow:hidden !important;
}
.clear{
    clear:both;
}
.dir_default{
    direction:ltr;
}
.form-control:focus{
    box-shadow: none;
}
.minHeight{
    min-height: 550px;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle{
    background: none;
    border: none;
    font-size: 14px;
    padding: 0;
    display: flex;
}